// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-community-single-js": () => import("/usr/src/src/pages/community-single.js" /* webpackChunkName: "component---src-pages-community-single-js" */),
  "component---src-pages-community-templates-guides-js": () => import("/usr/src/src/pages/community-templates-guides.js" /* webpackChunkName: "component---src-pages-community-templates-guides-js" */),
  "component---src-pages-waye-talks-single-js": () => import("/usr/src/src/pages/waye-talks-single.js" /* webpackChunkName: "component---src-pages-waye-talks-single-js" */),
  "component---src-pages-404-js": () => import("/usr/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/usr/src/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-community-js": () => import("/usr/src/src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-contact-us-js": () => import("/usr/src/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("/usr/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/usr/src/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-tools-and-resources-js": () => import("/usr/src/src/pages/tools-and-resources.js" /* webpackChunkName: "component---src-pages-tools-and-resources-js" */),
  "component---src-pages-waye-talks-js": () => import("/usr/src/src/pages/waye-talks.js" /* webpackChunkName: "component---src-pages-waye-talks-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/usr/src/.cache/data.json")

